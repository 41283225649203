import * as React from 'react';
import styled from 'styled-components';
import { utils } from 'react-media-player';

import { IMediaProps } from './PlayerControls';
import { SeekBar } from './podcastStyles';
import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';

export interface IPlayerProgressProps {
  media: IMediaProps;
}

const Wrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0 -0.5rem;
  order: 1;
  padding: 0 0 1.3rem;
  svg {
    width: 1rem;
    fill: white;
  }
  @media ${medias.largeMin} {
    order: unset;
    flex: 1;
    max-width: 450px;
    padding: 1.5rem 0;
  }
`;

const TimeIndicator = styled.span`
  display: block;
  font-size: 0.8em;
  position: absolute;
  bottom: 0;
  :first-of-type {
    left: 0;
  }
  :last-of-type {
    right: 0;
  }
  @media ${medias.largeMax} {
    :first-of-type {
      display: none;
    }
    :last-of-type {
      right: 0.5rem;
    }
  }
`;

const thumbStyles = `
  -webkit-appearance: none;
  background-color: ${theme.colors.primary};
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: ${theme.transitions.default};
  :active {
    transform: scale(1.5);
  }
`;

export const StyledProgress = styled(SeekBar)`
  -webkit-appearance: none;
  position: relative;
  display: block;
  background: linear-gradient(
      90deg,
      ${theme.colors.primary} 0%,
      ${theme.colors.primary} 100%
    )
    no-repeat 0 / 100%;
  width: 100%;
  height: 0.25rem;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  flex: 1;
  ::-webkit-slider-thumb {
    ${thumbStyles};
  }
  ::-moz-range-thumb {
    ${thumbStyles};
  }
  ::-ms-thumb {
    ${thumbStyles};
  }
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: rgba(255, 255, 255, 0.3);
    border-radius: inherit;
  }
`;

export const PlayerProgress: React.SFC<IPlayerProgressProps> = props => {
  const { duration, currentTime } = props.media;
  return (
    <Wrapper>
      <StyledProgress />
      <TimeIndicator>{utils.formatTime(currentTime)}</TimeIndicator>
      <TimeIndicator>-{utils.formatTime(duration - currentTime)}</TimeIndicator>
    </Wrapper>
  );
};

PlayerProgress.displayName = 'PlayerProgress';

export default PlayerProgress;
