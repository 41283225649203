import * as React from 'react';
import styled from 'styled-components';
import { VolumeIcon } from './podcastIcons';
import theme from '../../styles/theme';
import { Volume } from './podcastStyles';
import { medias } from '../../styles/helpers/mediaQueries';

const Wrapper = styled.div`
  display: none;
  @media ${medias.largeMin} {
    display: flex;
    align-items: center;
    margin-left: 3rem;
    svg {
      fill: white;
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

const thumbStyles = `
  -webkit-appearance: none;
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  transition: ${theme.transitions.default};
  :active {
    transform: scale(1.5);
  }
`;

export const StyledVolume = styled(Volume)`
  -webkit-appearance: none;
  position: relative;
  display: block;
  background: linear-gradient(90deg, white 0%, white 100%) no-repeat 0 / 100%;
  height: 5px;
  border-radius: 5px;
  margin: 0 auto;
  outline: 0;
  flex: 1;
  ::-webkit-slider-thumb {
    ${thumbStyles};
  }
  ::-moz-range-thumb {
    ${thumbStyles};
  }
  ::-ms-thumb {
    ${thumbStyles};
  }
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: rgba(255, 255, 255, 0.3);
    border-radius: inherit;
  }
`;

export const PlayerVolume: React.SFC<{}> = props => {
  return (
    <Wrapper>
      <VolumeIcon />
      <StyledVolume />
    </Wrapper>
  );
};

PlayerVolume.displayName = 'PlayerVolume';

export default PlayerVolume;
