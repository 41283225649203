import * as React from 'react';

export const keyFromString = (str: string, length: number = 10) => {
  return (
    str.substr(0, length / 2) + str.substr(str.length - length / 2, str.length)
  );
};

export const textToParagraphs = (body?: string) => {
  if (body) {
    const formattedText = boldString(body);
    return formattedText.split('\n').map(paragraph =>
      React.createElement('p', {
        key: keyFromString(paragraph),
        dangerouslySetInnerHTML: { __html: paragraph },
      })
    );
  } else {
    return null;
  }
};

export const parseParagraphs = (body: string) => {
  return body.split('\n').map(p => `<p>${p}</p>`);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const lowercaseFirstLetter = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const slugifyString = (str: string) => {
  const a = 'àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    str
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      /* eslint-disable */
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      /* eslint-enable */
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const removeWhitespace = (str: string) => {
  return str.replace(/\s/g, '');
};

// Useful to get only numbers from phone numbers
// Can be chained with removeWhitespace()
export const removeSpecialCharacters = (str: string) => {
  // eslint-disable-next-line
  return str.replace(/[\-\(\)\+]/g, '');
};

// Function meant to check if slugs coming from data start
// with a / or not in order to normalize it for usage in links
export const getProperSlug = (slug: string) => {
  return slug.substr(0, 1) === '/' ? slug : `/${slug}`;
};

export const checkIfAbsURL = (url: string) => {
  return url.includes('http');
};

export const replaceSpecialCharacters = (str: string) => {
  return str
    .replace(/[àáã]/gi, 'a')
    .replace(/[éê]/gi, 'e')
    .replace(/[í]/gi, 'i')
    .replace(/[óôõ]/gi, 'o')
    .replace(/[ú]/gi, 'u')
    .replace(/[ç]/gi, 'c');
};

export const getMediumURL = (slug: string) => {
  return `https://medium.com/data-hackers/${slug}`;
};

export const removeEmoji = (str: string) => {
  return str.replace(/:\w*:/gi, '');
};

export const boldString = (str: string) => {
  return str.replace(
    // eslint-disable-next-line
    /\*{2}(?=[^\s\*])(.*?)([^\s\*])\*{2}/gi,
    s => `<b>${s.replace(/\*/g, '')}</b>`
  );
};
