import { IUserTheme } from './themeDefinitions';

export const theme: IUserTheme = {
  fontSize: 16,
  fontSizeLg: 20,
  fontFamilyBody: 'neusa-next-std',
  familyCategoryBody: 'sans-serif',
  body: {
    maxWidth: 700,
    lineHeight: 1.5,
    margin: 2,
    lineHeightLg: 1.65,
  },
  bodyWeight: 300,
  headingWeight: 500,
  headings: [
    {
      fontSize: 24,
      lineHeight: 1.15,
      marginTop: 5,
      marginBottom: 25,
    },
    {
      fontSize: 22,
      lineHeight: 1.15,
      marginBottom: 25,
    },
    {
      fontSize: 20,
      lineHeight: 1.25,
      marginTop: 20,
      marginBottom: 15,
    },
    {
      fontSize: 16,
      lineHeight: 1.2,
      marginTop: 15,
      marginBottom: 10,
    },
  ],
  headingsLg: [
    {
      fontSize: 40,
      marginTop: 5,
      lineHeight: 1.25,
      marginBottom: 10,
    },
    {
      fontSize: 30,
      marginTop: 100,
      lineHeight: 1.3,
      marginBottom: 25,
    },
    {
      fontSize: 24,
      lineHeight: 1.25,
    },
    {
      fontSize: 20,
      lineHeight: 1.3,
    },
  ],
  colors: {
    bg: '#160739',
    text: '#FFF',
    accent: '#FF8811',
    primary: '#6F37FF',
    primaryLight: '#9E78FF',
    darkPurple: '#0C0029',
  },
  fontFamilyHeading: 'neusa-next-std',
  familyCategoryHeading: 'sans-serif',
  transitions: {
    slow: '.4s cubic-bezier(0.23, 1, 0.32, 1)',
    default: '.25s cubic-bezier(0.23, 1, 0.32, 1)',
    fast: '.2s cubic-bezier(0.23, 1, 0.32, 1)',
  },
  containerWidth: '1295px',
  lgContainerWidth: '1440px',
  smContainerWidth: '900px',
  boxShadow: {
    one:
      'box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.2)',
    two: '0px 8px 8px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.2)',
    three: '0px 16px 16px rgba(0, 0, 0, 0.2), 0px 8px 8px rgba(0, 0, 0, 0.2)',
    four: '0px 24px 24px rgba(0, 0, 0, 0.4), 0px 12px 12px rgba(0, 0, 0, 0.4)',
    five:
      '0px 32px 32px rgba(34, 34, 43, 0.2), 0px 16px 16px rgba(34, 34, 43, 0.2)',
  },
};

export default theme;
