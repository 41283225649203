import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { checkIfAbsURL } from '../utils/strings';
import theme from '../styles/theme';
import { hexToRgb } from '../styles/helpers/hexToRgb';

export interface IButtonProps {
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any;
  // Botão secundário em branco
  isSecondary?: boolean;
}

export const getNeonShadow = (color: string) =>
  `0 0 3px ${hexToRgb(color, 0.6)}, 0 0 14px ${hexToRgb(color, 0.4)}`;

export const buttonBaseStyles = (isSecondary?: boolean) => {
  const mainColor = isSecondary ? '#fff' : theme.colors.accent;
  const altColor = isSecondary ? theme.colors.accent : '#fff';
  return `
  font-weight: bold;
  text-align: center;
  color: ${theme.colors.bg};
  transition: ${theme.transitions.default};
  font-weight: 500;
  border-radius: 50px;
  padding: .75em 1.25em;
  min-width: 20ch;
  background: ${mainColor};
  box-shadow: ${getNeonShadow(mainColor)};
  text-decoration: none;
  :hover {
    background: ${altColor};
    box-shadow: ${getNeonShadow(altColor)};
  }
`;
};

export const Button: React.SFC<IButtonProps> = props => {
  const styles = buttonBaseStyles(props.isSecondary);
  const StyledLink = styled(Link)`
    ${styles};
  `;
  const StyledAnchor = styled.a`
    ${styles};
  `;
  const StyledButton = styled.button`
    ${styles};
  `;

  if (props.onClick) {
    return (
      <StyledButton onClick={props.onClick}>{props.children}</StyledButton>
    );
  }

  if (props.url && checkIfAbsURL(props.url)) {
    return (
      <StyledAnchor href={props.url} target="_blank" rel="noopener">
        {props.children}
      </StyledAnchor>
    );
  } else if (props.url) {
    return <StyledLink to={props.url}>{props.children}</StyledLink>;
  }
  return null;
};

Button.displayName = 'Button';

export default Button;
