import * as React from 'react';

export const PlayIcon = () => (
  <svg viewBox="0 0 25 31" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 .779v28.444c0 .6.61.975 1.097.675l22.547-14.222c.478-.3.478-1.041 0-1.341L1.097.113C.609-.197 0 .18 0 .78z" />
  </svg>
);

export const PauseIcon = () => (
  <svg viewBox="0 0 22 30" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zM0 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v28a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1z" />
  </svg>
);

export const VolumeIcon = () => (
  <svg viewBox="0 0 36 30" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.194 4.64a1.451 1.451 0 0 0-.647-.15c-.338 0-.666.113-.938.329L6.497 9.75h-4.95c-.825 0-1.5.675-1.5 1.5v7.5c0 .825.675 1.5 1.5 1.5h4.95l6.112 4.922c.272.216.61.328.938.328.215 0 .44-.047.647-.15a1.5 1.5 0 0 0 .853-1.35V6c0-.581-.328-1.106-.853-1.36zM35.954 15c0-5.56-2.176-10.762-6.132-14.672a1.126 1.126 0 1 0-1.584 1.603c3.524 3.479 5.456 8.12 5.456 13.07 0 4.95-1.94 9.59-5.457 13.068-.44.44-.45 1.153-.009 1.594a1.1 1.1 0 0 0 .797.337c.281 0 .572-.112.788-.328 3.965-3.9 6.14-9.113 6.14-14.672z" />
    <path d="M30.084 15c0-4.078-1.556-7.903-4.387-10.772a1.126 1.126 0 1 0-1.603 1.584c2.41 2.448 3.74 5.71 3.74 9.197 0 3.488-1.33 6.75-3.74 9.197-.44.441-.431 1.154.01 1.594.215.216.506.328.787.328a1.1 1.1 0 0 0 .797-.337A15.312 15.312 0 0 0 30.084 15z" />
    <path d="M20.953 8.11a1.126 1.126 0 1 0-1.603 1.584c1.387 1.406 2.156 3.29 2.156 5.306 0 2.006-.768 3.89-2.156 5.306-.44.441-.431 1.153.01 1.594.215.216.506.328.787.328a1.1 1.1 0 0 0 .797-.337c1.81-1.838 2.803-4.275 2.803-6.881.01-2.616-.985-5.063-2.794-6.9z" />
  </svg>
);

export const SkipButton: React.SFC<{ isInverted?: boolean }> = ({
  isInverted = false,
}) => (
  <svg viewBox="0 0 33 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 4.42V6.82c0 .14.127.227.229.155l4.696-3.318c.1-.07.1-.243 0-.313L16.228.025c-.101-.07-.228.017-.228.157V2.42C7.119 2.683 0 9.966 0 18.912c0 9.112 7.387 16.5 16.5 16.5S33 28.024 33 18.912a1 1 0 1 0-2 0c0 8.008-6.492 14.5-14.5 14.5S2 26.92 2 18.912C2 11.07 8.223 4.684 16 4.42z"
      style={{ transform: isInverted ? 'scaleX(-1) translateX(-100%)' : '' }}
    />
    <path d="M12.047 24h-.938V13.75l-3.093 1.148v-.875l3.875-1.437h.156V24zM17.203 18.172l.555-5.547h5.687v.867h-4.883l-.398 3.844c.61-.417 1.323-.625 2.14-.625 1.027 0 1.844.333 2.454 1 .61.667.914 1.555.914 2.664 0 1.198-.297 2.128-.89 2.79-.595.66-1.43.991-2.509.991-.99 0-1.791-.276-2.406-.828-.614-.552-.966-1.325-1.055-2.32h.899c.088.776.352 1.364.789 1.765.438.396 1.029.594 1.773.594.813 0 1.425-.258 1.836-.773.417-.516.625-1.25.625-2.203 0-.834-.231-1.513-.695-2.04-.463-.525-1.096-.788-1.898-.788a3.51 3.51 0 0 0-1.157.171c-.328.11-.674.326-1.039.649l-.742-.211z" />
  </svg>
);
