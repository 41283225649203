// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-obrigado-tsx": () => import("./../../../src/pages/obrigado.tsx" /* webpackChunkName: "component---src-pages-obrigado-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */),
  "component---src-templates-generic-page-generic-page-template-tsx": () => import("./../../../src/templates/GenericPage/GenericPageTemplate.tsx" /* webpackChunkName: "component---src-templates-generic-page-generic-page-template-tsx" */),
  "component---src-templates-home-home-template-tsx": () => import("./../../../src/templates/Home/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-home-template-tsx" */),
  "component---src-templates-podcast-list-podcast-list-template-tsx": () => import("./../../../src/templates/PodcastList/PodcastListTemplate.tsx" /* webpackChunkName: "component---src-templates-podcast-list-podcast-list-template-tsx" */),
  "component---src-templates-post-list-post-list-template-tsx": () => import("./../../../src/templates/PostList/PostListTemplate.tsx" /* webpackChunkName: "component---src-templates-post-list-post-list-template-tsx" */)
}

