import styled from 'styled-components';
import { controls } from 'react-media-player';
import { Link } from 'gatsby';

import theme from '../../styles/theme';
import { medias } from '../../styles/helpers/mediaQueries';
import { getNeonShadow } from '../Button';

export const normalizedControls = controls || {};

export const {
  CurrentTime = Link,
  PlayPause = Link,
  Progress = Link,
  SeekBar = Link,
  Duration = Link,
  Volume = Link,
} = normalizedControls;

interface IPodcastComponentProps {
  isShown: boolean;
  isHidden: boolean;
}

export const PodcastWrapper = styled.aside`
  background: ${theme.colors.darkPurple};
  /* background: red; */
  display: flex;
  position: fixed;
  justify-content: center;
  z-index: 50;
  bottom: 0;
  left: 0;
  width: 100%;
  flex-direction: column;
  padding: 0 0.5rem 0.25rem;
  box-shadow: 0px -6px 6px rgba(34, 34, 43, 0.1),
    0px -3px 3px rgba(34, 34, 43, 0.1);
  transform: ${({ isShown, isHidden }: IPodcastComponentProps) =>
    isShown && !isHidden ? 'translateY(0)' : 'translateY(105%)'};
  transition: ${theme.transitions.slow};
  @media ${medias.largeMin} {
    flex-direction: row;
    padding: 0.5rem 1.5rem 0.25rem;
    align-items: center;
  }
`;

export const HeightPlaceholder = styled.div`
  height: 134px;
  @media ${medias.largeMin} {
    height: 80px;
  }
  @media ${medias.laptopMin} {
    height: 100px;
  }
`;

export const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin: 0 0.5rem;
  }
  margin: 0 -0.5rem;
`;

export const ToggleButton = styled.button`
  background: white;
  box-shadow: ${getNeonShadow('#fff')};
  border-radius: 50%;
  font-size: 2em;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${theme.transitions.default};
  position: absolute;
  right: 1rem;
  transition: ${theme.transitions.default};
  outline: none;
  top: ${({ isShown, isHidden }: IPodcastComponentProps) =>
    isShown && !isHidden ? '-1.25em' : '-1.5em'};
  @media ${medias.largeMin} {
    top: ${({ isShown, isHidden }: IPodcastComponentProps) =>
      isShown && !isHidden ? '-.5em' : '-1.5em'};
  }
  svg {
    transition: ${theme.transitions.default};
    width: 0.35em;
    transform: ${({ isShown, isHidden }: IPodcastComponentProps) =>
      isShown && !isHidden ? 'rotate(0)' : 'rotate(180deg)'};
  }
  :hover,
  :focus {
    box-shadow: ${getNeonShadow(theme.colors.primaryLight)};
    transform: scale(1.05);
  }
`;
