import * as React from 'react';
import styled from 'styled-components';
import { PlayIcon, PauseIcon, SkipButton } from './podcastIcons';
import { NoParamsAny, SingleParamAny } from '../../utils/types';
import { medias } from '../../styles/helpers/mediaQueries';

export interface IMediaProps {
  duration: number;
  progress: number;
  currentTime: number;
  isPlaying: boolean;
  pause: NoParamsAny;
  play: NoParamsAny;
  playPause: NoParamsAny;
  skipTime: SingleParamAny;
  seekTo: SingleParamAny;
}

export interface IPlayButtonProps {
  media: IMediaProps;
}

const Wrapper = styled.div`
  margin: 0.5rem auto 1rem;
  order: 2;
  @media ${medias.largeMin} {
    order: unset;
    margin: 0 2.5rem;
  }
`;

const IconButton = styled.button`
  :nth-of-type(2) {
    margin: 0 1rem;
    svg {
      width: 1.25rem;
    }
  }
  svg {
    width: 1.5rem;
    fill: white;
  }
`;

export const PlayerControls: React.SFC<IPlayButtonProps> = props => {
  const { isPlaying, playPause } = props.media;
  const goBackwards = () => {
    const { media } = props;
    media.seekTo(media.currentTime - 15);
  };
  const goForward = () => {
    const { media } = props;
    media.seekTo(media.currentTime + 15);
  };
  return (
    <Wrapper>
      <IconButton onClick={goBackwards}>
        <SkipButton isInverted={true} />
      </IconButton>
      <IconButton onClick={playPause}>
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </IconButton>
      <IconButton onClick={goForward}>
        <SkipButton />
      </IconButton>
    </Wrapper>
  );
};

PlayerControls.displayName = 'PlayerControls';

export default PlayerControls;

// export default withMediaProps(({ media }: { media: IMediaProps }) => (
//   <PlayerControls media={media} />
// ));
