import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { medias } from '../../styles/helpers/mediaQueries';

export interface IPodcastMetaProps {
  title: string;
  date: string;
}

const Wrapper = styled.div`
  text-align: center;
  order: 3;
  @media ${medias.largeMin} {
    text-align: left;
    order: unset;
    flex: 1 0 200px;
    max-width: 300px;
  }
  @media ${medias.laptopMin} {
    max-width: 450px;
    display: flex;
    align-items: center;
  }
`;

const ImagesWrapper = styled.div`
  display: none;
  @media ${medias.laptopMin} {
    display: block;
    flex: 1 0 50px;
    max-width: 80px;
    margin-right: 0.5rem;
  }
`;

const scrollAnimation = keyframes`
  30% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(-100%);
    opacity: 1;
  }
  95.1% {
    opacity: 0;
    transform: translateX(101%);
    transition: none;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const TitlesWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  h2,
  h3 {
    font-size: 1rem;
    line-height: 1;
    white-space: nowrap;
    margin-top: 0;
  }
  h2 {
    width: min-content;
    margin-bottom: 0.5rem;
    animation: ${scrollAnimation} linear 20s infinite;
    text-align: left;
  }
  h3 {
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
`;

export const PodcastMeta: React.SFC<IPodcastMetaProps> = props => {
  const date = new Date(Date.parse(props.date));
  return (
    <Wrapper>
      <ImagesWrapper>
        <img
          src="https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded_nologo400/1044213/1044213-1537805558117-18f2d5f027c02.jpg"
          alt="Capa do Podcast"
        />
      </ImagesWrapper>
      <TitlesWrapper>
        <h2>{props.title}</h2>
        <h3>
          {' '}
          {date.toLocaleDateString('pt', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h3>
      </TitlesWrapper>
    </Wrapper>
  );
};

PodcastMeta.displayName = 'PodcastMeta';

export default PodcastMeta;
