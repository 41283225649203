import * as React from 'react';

import FallbackPageMeta from '../components/FallbackPageMeta';
import PodcastPlayer, {
  PodcastPlayerContext,
  IPodcastPlayInfo,
  TChangeCurrentPodcast,
} from '../components/Podcast/PodcastPlayer';

interface ILayoutBasisState {
  currentPodcast?: IPodcastPlayInfo;
  isShown: boolean;
  isHidden: boolean;
}

class LayoutBasis extends React.Component<any, ILayoutBasisState> {
  state = {
    currentPodcast: undefined,
    isShown: false,
    isHidden: false,
  };

  hidePlayer = () => {
    this.setState(prev => ({ isHidden: !prev.isHidden }));
  };

  changeCurrentPodcast: TChangeCurrentPodcast = currentPodcast => {
    this.setState({ currentPodcast, isShown: true });
  };

  render() {
    const { isShown, isHidden, currentPodcast } = this.state;
    return (
      <>
        <FallbackPageMeta />
        <PodcastPlayerContext.Provider
          value={{
            isShown,
            currentPodcast,
            changeCurrentPodcast: this.changeCurrentPodcast,
            hidePlayer: this.hidePlayer,
          }}
        >
          <PodcastPlayer
            isShown={isShown}
            isHidden={isHidden}
            podcast={currentPodcast}
          />
          {this.props.children}
        </PodcastPlayerContext.Provider>
      </>
    );
  }
}

export default LayoutBasis;
