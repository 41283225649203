import * as React from 'react';
import { Media, Player, withMediaProps } from 'react-media-player';

import {
  PodcastWrapper,
  HeightPlaceholder,
  ToggleButton,
} from './podcastStyles';
import PlayerControls from './PlayerControls';
import PodcastMeta from './PodcastMeta';
import PlayerProgress from './PlayerProgress';
import PlayerVolume from './PlayerVolume';
import { NoParamsAny } from '../../utils/types';
import { Chevron } from '../Slack/slackIcons';

export type TChangeCurrentPodcast = (currentPodcast: IPodcastPlayInfo) => any;

export interface IPodcastPlayInfo {
  url: string;
  title: string;
  date: string;
}

interface IPodcastPlayProps {
  isShown: boolean;
  isHidden: boolean;
  podcast?: IPodcastPlayInfo;
}

interface IPodcastContext {
  isShown: boolean;
  changeCurrentPodcast: TChangeCurrentPodcast;
  hidePlayer: NoParamsAny;
  currentPodcast?: IPodcastPlayInfo;
}

// Criamos o contexto do player do podcast com funções padrão
export const PodcastPlayerContext = React.createContext<IPodcastContext>({
  isShown: false,
  changeCurrentPodcast: p =>
    console.log('Não conseguimos trocar o episódio sendo tocado'),
  hidePlayer: () => console.error('Não conseguimos esconder o player'),
});

export const PodcastPlayer: React.SFC<IPodcastPlayProps> = props => {
  const { isShown, isHidden, podcast } = props;
  let Controls = <div />;
  let Progress = <div />;
  if (typeof window !== 'undefined' && window.innerWidth) {
    Controls = withMediaProps(PlayerControls);
    Progress = withMediaProps(PlayerProgress);
  }
  return (
    <PodcastWrapper
      aria-hidden={isShown}
      isShown={isShown && typeof podcast !== 'undefined'}
      isHidden={isHidden}
    >
      {typeof podcast !== 'undefined' ? (
        <PodcastPlayerContext.Consumer>
          {({ hidePlayer }) => (
            <ToggleButton
              onClick={hidePlayer}
              isShown={isShown}
              isHidden={isHidden}
            >
              <Chevron />
            </ToggleButton>
          )}
        </PodcastPlayerContext.Consumer>
      ) : null}
      {isShown && typeof podcast !== 'undefined' ? (
        <>
          <PodcastMeta date={podcast.date} title={podcast.title} />
          <Media>
            <>
              <Player
                src={podcast.url}
                vendor="audio"
                useAudioObject={false}
                autoPlay={true}
              />
              {<Controls />}
              {<Progress />}
              <PlayerVolume />
            </>
          </Media>
        </>
      ) : (
        <HeightPlaceholder aria-hidden={true} />
      )}
    </PodcastWrapper>
  );
};

PodcastPlayer.displayName = 'PodcastPlayer';

export default PodcastPlayer;
