import * as React from 'react';

export const BellIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <path d="M77.344 56.702V44.979c0-11.69-6.915-21.734-17.28-25.795C59.023 14.505 54.906 11 50 11s-9.023 3.505-10.064 8.185c-10.365 4.061-17.28 14.105-17.28 25.795v11.723l-6.16 6.325a2.998 2.998 0 0 0-.851 2.093v8.93a2.905 2.905 0 0 0 2.905 2.905h19.381C37.946 83.375 43.579 89 50 89c6.421 0 12.054-5.625 12.069-12.044H81.45a2.905 2.905 0 0 0 2.905-2.905v-8.93c0-.782-.305-1.533-.851-2.093l-6.16-6.326zM50 83.189c-3.433 0-6.242-3.236-6.258-6.234h12.517c-.017 2.998-2.826 6.234-6.259 6.234zm28.544-12.044H21.456v-4.883l7.011-7.198V44.979c0-10.591 7.011-19.293 17.026-21.412v-2.062c0-2.504 2.003-4.695 4.507-4.695 2.504 0 4.507 2.191 4.507 4.695v2.062c10.016 2.118 17.027 10.821 17.027 21.412v14.085l7.011 7.198v4.883z" />
  </svg>
);

export const ChatIcon = () => (
  <svg viewBox="0 0 78 80" xmlns="http://www.w3.org/2000/svg">
    <path d="M78 39C78 17.5 60.5 0 39 0S0 17.5 0 39s17.5 39 39 39c4.4 0 8.7-.8 12.8-2.2l20.1 3.5h.5c.9 0 1.7-.4 2.3-1.1.7-.8.8-2 .4-3l-5.3-12.4c2.3-3 4.2-6.2 5.5-9.6C77.1 48.7 78 43.9 78 39zM49.615 67.347A30.067 30.067 0 0 1 39 69.28c-16.8 0-30.277-13.532-30.277-30.286S22.385 8.615 39.185 8.615c16.8 0 30.462 13.625 30.462 30.379 0 3.774-.74 7.548-2.124 11.047a30.32 30.32 0 0 1-5.17 8.56c-.738.737-.83 1.934-.46 2.854L65.4 69.74l-14.307-2.485c-.555-.092-1.016-.092-1.478.092z" />
    <path d="M17 32a4 4 0 0 1 4-4h32a4 4 0 0 1 0 8H21a4 4 0 0 1-4-4zM17 48a4 4 0 0 1 4-4h26a4 4 0 0 1 0 8H21a4 4 0 0 1-4-4z" />
  </svg>
);

export const Cross = () => (
  <svg viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.4336 0.755859C35.1621 1.09717 35 1.52979 35 2V35H2C0.895508 35 0 35.8955 0 37V45C0 46.1045 0.895508 47 2 47H35V80C35 80.8276 35.5029 81.5386 36.2207 81.8423C36.46 81.9438 36.7236 82 37 82H45C46.1045 82 47 81.1045 47 80V47H80C81.1045 47 82 46.1045 82 45V37C82 35.8955 81.1045 35 80 35H47V2C47 0.895508 46.1045 0 45 0H37C36.3662 0 35.8008 0.29541 35.4336 0.755859Z" />
  </svg>
);

export const MenuDots = () => (
  <svg viewBox="0 0 5 19" xmlns="http://www.w3.org/2000/svg">
    <circle cx="2.5" cy="2.5" r="2.5" />
    <circle cx="2.5" cy="9.5" r="2.5" />
    <circle cx="2.5" cy="16.5" r="2.5" />
  </svg>
);

export const MagnifierIcon = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.618 16.032a9 9 0 1 1 1.414-1.414l3.675 3.675a1 1 0 0 1-1.414 1.414l-3.675-3.675zM2 9a7 7 0 1 1 12.04 4.857 1.014 1.014 0 0 0-.182.183A7 7 0 0 1 2 9z"
    />
  </svg>
);

export const Chevron = () => (
  <svg viewBox="0 0 79 47" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.508 1.843a4 4 0 0 0-5.656 0L2.023 4.67a4 4 0 0 0 0 5.657l34.648 34.648a4 4 0 0 0 5.657 0l2.829-2.828.018-.018 31.802-31.802a4 4 0 0 0 0-5.656l-2.829-2.829a4 4 0 0 0-5.657 0l-28.99 28.991L10.508 1.843z" />
  </svg>
);

export const ImageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.279 1.688H4.721a3.037 3.037 0 0 0-3.033 3.033V19.28a3.037 3.037 0 0 0 3.033 3.033H19.28a3.037 3.037 0 0 0 3.033-3.033V4.721a3.037 3.037 0 0 0-3.034-3.033zM4.721 2.901H19.28c1.004 0 1.82.816 1.82 1.82v10.837l-3.033-2.528-3.623 3.019-6.733-6.121-4.81 5.344V4.721c0-1.004.816-1.82 1.82-1.82zm14.558 18.198H4.721a1.821 1.821 0 0 1-1.82-1.82v-2.194l4.896-5.44 6.613 6.011 3.657-3.048 3.033 2.527v2.142a1.823 1.823 0 0 1-1.821 1.822z" />
    <path d="M16.246 10.787c1.672 0 3.033-1.361 3.033-3.033s-1.361-3.033-3.033-3.033-3.033 1.361-3.033 3.033 1.361 3.033 3.033 3.033zm0-4.853c1.004 0 1.82.816 1.82 1.82s-.816 1.82-1.82 1.82-1.82-.816-1.82-1.82.817-1.82 1.82-1.82z" />
  </svg>
);
