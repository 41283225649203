import * as React from 'react';
import { Helmet } from 'react-helmet';
import { IImageField } from '../utils/types';

export interface IPageMeta {
  title: string;
  seoTitle?: string;
  seoDescription?: string;
  ogImage?: IImageField;
  schemaVocab?: string;
  socialTitle?: string;
  lang?: string;
  locale?: string;
  twitterUsername?: string;
  canonicalUrl?: string;
  // There're also player and app cards
  twitterCard?: 'summary' | 'summary_large_image';
  // Full list: https://developers.facebook.com/docs/reference/opengraph#object-type
  ogType?:
    | 'article'
    | 'website'
    | 'book'
    | 'place'
    | 'product'
    | 'profile'
    | 'business.business';
  fbAppId?: string;
  children?: React.ReactElement<any> | Array<React.ReactElement<any>>;
}

export const PageMeta: React.SFC<IPageMeta> = props => {
  const description = props.seoDescription
    ? `${props.seoDescription.substr(0, 145)}...`
    : `Saiba mais`;
  const title = props.seoTitle || `${props.title} - Data Hackers`;
  const imgFixed =
    props.ogImage && props.ogImage.asset && props.ogImage.asset.fixed;
  return (
    <Helmet>
      {/* Basic Info */}
      <html lang="pt" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {props.lang && <html lang={props.lang} />}

      <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/a82573552d9540c9dd083ddc6/0c6d2c62fdbb96049a7fbd4d9.js");`}</script>

      {/* Social Sharing */}
      <meta
        property="og:title"
        name="og:title"
        content={props.socialTitle || title}
      />
      <meta
        property="og:description"
        name="og:description"
        content={props.socialTitle || title}
      />
      {props.locale && (
        <meta property="og:locale" name="og:locale" content={props.locale} />
      )}
      {props.canonicalUrl && (
        <meta property="og:url" name="og:url" content={props.canonicalUrl} />
      )}
      {props.twitterUsername && (
        <meta name="twitter:site" content={props.twitterUsername} />
      )}
      {props.twitterCard && (
        <meta name="twitter:card" content={props.twitterCard} />
      )}
      {props.fbAppId && <meta name="fb:app_id" content={props.fbAppId} />}
      {imgFixed && (
        <meta property="og:image" name="og:image" content={imgFixed.src} />
      )}
      {imgFixed && (
        <meta property="og:image:width" name="og:image:width" content="1200" />
      )}
      {imgFixed && (
        <meta name="og:image:height" property="og:image:height" content="630" />
      )}
      {imgFixed && <meta name="twitter:image" content={imgFixed.src} />}

      {props.children}

      {/* Schema.org */}
      <body
        vocab="https://schema.org/"
        typeof={props.schemaVocab || 'WebSite'}
      />
    </Helmet>
  );
};

PageMeta.displayName = 'PageMeta';

export default PageMeta;
