import React from 'react';
import Layout from './src/layouts/LayoutBasis.tsx';

// Criamos um componente customizado pra envolver todas as
// páginas no intuito de preservar o estado do player de podcasts ;)
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`);
  }
};
